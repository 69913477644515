import axios from "axios"

export const apiClientAuth = axios.create({
    baseURL: String(process.env.VUE_APP_BASE_URL),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'Application/json',
      'Application-Key': 'xrt0ehEhG1ZYt9HnEcse'
    }
  })
  
  apiClientAuth.interceptors.request.use(
    config => {
      let token = localStorage.getItem('access-token')
  
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`
      }
  
      return config
    },
  
    (error) => {
      return Promise.reject(error)
    }
  )
  
  apiClientAuth.interceptors.response.use(
    (response) => response,
  
    (error) => {
       if (error.response.status === 403) {
        localStorage.removeItem('access-token')
      } 
      
      return Promise.reject(error)
    }
  )
  